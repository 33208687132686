import { createReducer, on } from '@ngrx/store';
import { AuthState } from '../states/auth.state';
import { AuthActions } from '../actions/auth.actions';
import { User } from 'src/app/models/auth/admin-tool.type';
import { jwtDecode } from 'jwt-decode';

const INIT_STATE: AuthState = {
  tokenDetails: undefined,
  user: undefined,
};

export const authReducers = createReducer(
  INIT_STATE,
  on(
    AuthActions.getUserTenantsSuccess,
    (state: AuthState, { tenants }): AuthState => ({
      ...state,
      user: {
        ...(state.user as User),
        tenants,
        active_tenant: tenants.find((tenant) => tenant.name !== 'root')!,
      },
    }),
  ),
  on(
    AuthActions.getUserRolesSuccess,
    (state: AuthState, { roles }): AuthState => ({
      ...state,
      user: {
        ...(state.user as User),
        roles,
        active_role: roles.at(0)!,
      },
    }),
  ),
  on(
    AuthActions.getUserFeaturesSuccess,
    (state: AuthState, { features }): AuthState => ({
      ...state,
      user: {
        ...(state.user as User),
        features,
      },
    }),
  ),
  on(AuthActions.setTokenDetails, (state: AuthState, { tokenDetails }): AuthState => {
    const given_name = jwtDecode<{ name: string }>(tokenDetails.idToken).name;
    const auth = { tokenDetails, user: { ...state.user, given_name } as User };
    return { ...state, ...auth };
  }),
  on(
    AuthActions.setActiveTenant,
    (state: AuthState, { active_tenant }): AuthState => ({
      ...state,
      user: {
        ...(state.user as User),
        active_tenant,
      },
    }),
  ),
  on(
    AuthActions.setActiveRole,
    (state: AuthState, { active_role }): AuthState => ({
      ...state,
      user: {
        ...(state.user as User),
        active_role,
      },
    }),
  ),
  on(AuthActions.logout, (state: AuthState): AuthState => ({ ...state, tokenDetails: undefined, user: undefined })),
);
