import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';
@Injectable()
export class MetadataInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const active_tenant = localStorage.getItem(`${environment.localStorageKeyPrefix}_tenant`);
    const excludedResources: Array<string> = ['https://diassteeldecarbonstorage.blob.core.windows.net'];
    let headers: HttpHeaders = req.headers;

    if (!excludedResources.some((res) => req.url.includes(res))) {
      headers = req.headers.append('x-solution', environment.solution);

      if (active_tenant) {
        headers = headers.append('x-company', active_tenant);
        if (!environment.production && !req.url.includes(environment.admin_tool_url)) {
          const user = JSON.parse(localStorage.getItem(`${environment.localStorageKeyPrefix}_auth`) || '{"tokenDetails": {"account" : { "username" : ""}}}');
          const userName = user.tokenDetails?.account?.username ?? 'hussain.sherief@focaloid.com';
          headers = headers.append('x-email', userName);
          headers = headers.append('x-user', userName);
        }
      }
    }

    const clonedReq = req.clone({ headers });

    return next.handle(clonedReq);
  }
}
