import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LanguageOption } from 'src/app/models/core/language-option.type';
import { LayoutMode } from 'src/app/models/core/layout-mode.type';
import { SystemNotification } from 'src/app/models/core/system-notification.interface';
import { Theme } from 'src/app/models/core/theme.type';

export const SystemActions = createActionGroup({
  source: 'System',
  events: {
    OpenPage: props<{ url: string; target?: string }>(),
    Redirect: props<{ url: string }>(),
    RedirectToCourtesyPage: emptyProps(),
    RedirectToHomePage: emptyProps(),
    SetCurrentLang: props<{ currentLang: LanguageOption }>(),
    ToggleLanguage: emptyProps(),
    SetLayoutMode: props<{ layoutMode: LayoutMode }>(),
    SetNotification: props<{ notification: SystemNotification | undefined }>(),
    SetTheme: props<{ theme: Theme }>(),
    ChangeTheme: emptyProps(),
    StartLoading: emptyProps(),
    StopLoading: emptyProps(),
  },
});
