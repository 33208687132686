import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { MsalBroadcastService, MsalGuard, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';

import { ROUTES } from './app.routes';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './configs/msal.config';
import { AzureTokenInterceptor } from './providers/interceptors/azure-token.interceptor';
import { LoadingInterceptor } from './providers/interceptors/loading.interceptor';
import { MetadataInterceptor } from './providers/interceptors/metadata.interceptor';
import { NotificationInterceptor } from './providers/interceptors/notification.interceptor';
import { PowerBITokenInterceptor } from './providers/interceptors/powerbi-token.interceptor';

import * as translate from './configs/translate.config';
import * as store from './store';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(ROUTES, withComponentInputBinding()),
    provideStore(store.reducers, { metaReducers: store.metaReducers }),
    provideStoreDevtools(store.devtoolsConfig),
    provideEffects(store.effects),
    provideAnimations(),
    importProvidersFrom(TranslateModule.forRoot(translate.config)),
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: HTTP_INTERCEPTORS, useClass: AzureTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MetadataInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PowerBITokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
};
