import { POWERBI_CONFIG } from '../app/configs/powerbi.config';

const company = {
  logo: './assets/img/logo.png',
  logoBg: 'bgffd300',
  name: 'Cube DIAS GHG',
  role: '*',
};

const bypass_auth_token_resources = ['blob.core.windows.net', 'api.powerbi.com', '/assets/i18n/'];

const features = {
  powerbi: { ...POWERBI_CONFIG, base_url: 'https://api.powerbi.com/v1.0/myOrg' },
};

const solution = 'dias_ghg';

export const globalEnvironment = {
  production: false,
  company,
  solution,
  ...features,
  date_format: 'MM-dd-yyyy HH:mm',
  first_available_date: '2021-01-01',
  bypass_auth_token_resources,
};
