import { createFeature } from '@ngrx/store';
import { reducer } from './powerbi.reducers';
import { PowerBIEffects } from './powerbi.effects';
import { MSAccessTokenResponse } from '../models/ms-access-token.interface';
import { PowerBITokenResponse } from '../models/powerbi-token.interface';
import { PowerBIConfiguration } from '../models/powerbi-configuration.interface';

export interface PowerBIState {
  powerbi_access_token: MSAccessTokenResponse | undefined;
  powerbi_embed_token: PowerBITokenResponse | undefined;
  powerbi_embed_configuration: PowerBIConfiguration | undefined;
}

export const feature = createFeature({
  name: 'powerbi',
  reducer,
});

export { reducer };

export const effects = [PowerBIEffects];

export const { selectPowerbi_access_token, selectPowerbi_embed_token, selectPowerbi_embed_configuration } = feature;
