/* eslint-disable @ngrx/no-typed-global-store */
import { BreakpointObserver } from '@angular/cdk/layout';
import { Directive, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { delay, map } from 'rxjs';

import { SystemActions } from '../store/actions/system.actions';
import { AppState } from '../store/states/app.state';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[cubeLayoutModeHandler]', standalone: true })
export class CubeLayoutModeHandlerDirective {
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly store: Store<AppState> = inject(Store);

  constructor() {
    this.breakpointObserver
      .observe(['(max-width: 575.98px)'])
      .pipe(
        delay(1),
        map((res) => res.matches),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: (isResponsive: boolean): void => {
          const layoutMode = isResponsive ? 'responsive' : 'desktop';
          this.store.dispatch(SystemActions.setLayoutMode({ layoutMode }));
        },
      });
  }
}
