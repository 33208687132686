import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as pbi from 'powerbi-client';
import { catchError, map, of, switchMap } from 'rxjs';

import { PowerBIActions } from './powerbi.actions';
import { AppState } from 'src/app/store/states/app.state';
import { MSAccessTokenResponse } from '../models/ms-access-token.interface';
import { PowerBiHttpService } from '../providers/powerbi-http.service';
import { PowerBITokenResponse } from '../models/powerbi-token.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class PowerBIEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly httpService: PowerBiHttpService,
    private readonly store: Store<AppState>,
  ) {}

  powerbiLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PowerBIActions.powerBIServicePrincipalLogin),
      switchMap(() =>
        this.httpService.getMicrosoftAccessToken().pipe(
          map((response: MSAccessTokenResponse) => PowerBIActions.powerBIServicePrincipalLoginSuccess({ response })),
          catchError((error: HttpErrorResponse) => {
            // if (error.status === HttpStatusCode.Forbidden) {
            //   return of(PowerBIActions.powerBIServicePrincipalLoginSuccess({ response: true }));
            // } else {
            return of(PowerBIActions.powerBIServicePrincipalLoginFailure({ error }));
            // }
          }),
        ),
      ),
    );
  });

  powerbiLoginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PowerBIActions.powerBIServicePrincipalLoginSuccess),
      map(() => PowerBIActions.powerBIToken()),
    );
  });

  powerbiToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PowerBIActions.powerBIToken),
      switchMap(() =>
        this.httpService.getPowerBiEmbedToken().pipe(
          map((response: PowerBITokenResponse) => PowerBIActions.powerBITokenSuccess({ response })),
          catchError((error: HttpErrorResponse) => of(PowerBIActions.powerBITokenFailure({ error }))),
        ),
      ),
    );
  });

  powerbiTokenResponse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PowerBIActions.powerBITokenSuccess),
      map(({ response }) => {
        const configuration = {
          type: 'report',
          tokenType: pbi.models.TokenType.Embed,
          permissions: pbi.models.Permissions.Read,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${environment.powerbi.reportId}&groupId=${environment.powerbi.groupId}&filterPaneEnabled=False`,
          id: environment.powerbi.reportId,
          accessToken: response.token,
        };
        return PowerBIActions.powerBITokenConfiguration({ configuration });
      }),
    );
  });
}
