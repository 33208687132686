/* eslint-disable @ngrx/no-typed-global-store */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap, take, throwError } from 'rxjs';
import { TokenDetails } from 'src/app/models/auth/token-details.type';
import { selectTokenDetails } from 'src/app/store/selectors/auth.selectors';
import { AppState } from 'src/app/store/states/app.state';
import { environment } from 'src/environments/environment';

@Injectable()
export class AzureTokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly msalService: MsalService,
    private readonly store: Store<AppState>,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const request = this.tokenRequest();
    return this.store.select(selectTokenDetails).pipe(
      take(1),
      map((tokenDetails: TokenDetails | undefined) => ({
        isValidToken: this.isValidToken(tokenDetails?.idTokenClaims?.exp),
        idToken: tokenDetails?.idToken || '',
        appendToken: this.resourceNeedsToken(req.url),
      })),
      switchMap(({ isValidToken, idToken, appendToken }) => {
        if (!isValidToken && appendToken) {
          return this.msalService.acquireTokenSilent(request).pipe(map(({ idToken }) => ({ idToken, appendToken })));
        } else {
          return of({ idToken, appendToken });
        }
      }),
      switchMap(({ idToken, appendToken }) => {
        const clonedReq = this.cloneRequest(req, idToken, appendToken);
        return next.handle(clonedReq);
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === HttpStatusCode.Unauthorized || err instanceof InteractionRequiredAuthError) {
          this.msalService.acquireTokenRedirect(request);
        }
        return throwError(() => err);
      }),
    );
  }

  private isValidToken(expiration: number | undefined): boolean {
    return new Date(new Date().getTime() - 1000 * 300).valueOf() < (expiration || 1) * 1000;
  }

  private resourceNeedsToken(request_url: string): boolean {
    return environment.bypass_auth_token_resources.every((resource) => !request_url.includes(resource));
  }

  private tokenRequest(): SilentRequest | RedirectRequest {
    const account = this.msalService.instance.getActiveAccount() ?? undefined;

    return {
      account,
      scopes: [],
    };
  }

  private cloneRequest(req: HttpRequest<unknown>, idToken: string, append_token: boolean): HttpRequest<unknown> {
    if (append_token) {
      const clonedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      return clonedReq;
    } else {
      return req;
    }
  }
}
