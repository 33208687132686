import { createReducer, on } from '@ngrx/store';
import { PowerBIState } from '.';
import { PowerBIActions } from './powerbi.actions';

const INIT_STATE: PowerBIState = {
  powerbi_access_token: undefined,
  powerbi_embed_token: undefined,
  powerbi_embed_configuration: undefined,
};

export const reducer = createReducer(
  INIT_STATE,
  on(PowerBIActions.powerBIServicePrincipalLoginSuccess, (state: PowerBIState, { response }): PowerBIState => ({ ...state, powerbi_access_token: response })),
  on(PowerBIActions.powerBITokenSuccess, (state: PowerBIState, { response }): PowerBIState => ({ ...state, powerbi_embed_token: response })),
  on(PowerBIActions.powerBITokenConfiguration, (state: PowerBIState, { configuration }): PowerBIState => ({ ...state, powerbi_embed_configuration: configuration })),
);
