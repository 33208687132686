import { createSelector } from '@ngrx/store';
import { TokenDetails } from 'src/app/models/auth/token-details.type';
import { AppState } from '../states/app.state';
import { AuthState } from '../states/auth.state';
import { Tenant, User } from 'src/app/models/auth/admin-tool.type';

const authState = (state: AppState): AuthState => state.auth;
export const selectTokenDetails = createSelector(authState, (state: AuthState): TokenDetails | undefined => state.tokenDetails);
export const selectUserDetails = createSelector(authState, (state: AuthState): User | undefined => state.user);
export const selectUserTenants = createSelector(authState, (state: AuthState): Array<Tenant> => state.user?.tenants ?? new Array<Tenant>());
export const selectActiveTenant = createSelector(selectUserDetails, (state: User | undefined): string => state?.active_tenant?.name ?? '');
