import { HttpClient } from '@angular/common/http';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export class CubeMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (params.interpolateParams as any)?.missing || 'Missing translation';
  }
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const config: TranslateModuleConfig = {
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: httpTranslateLoader,
    deps: [HttpClient],
  },
  isolate: false,
  missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CubeMissingTranslationHandler },
};

export const locales: Array<{ code: string; label: string }> = [
  { code: 'it', label: 'Italiano' },
  { code: 'en', label: 'English' },
];
