import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { SystemActions } from '../actions/system.actions';
import { environment } from 'src/environments/environment';

@Injectable()
export class SystemEffects {
  private readonly actions$: Actions = inject(Actions);
  private readonly router: Router = inject(Router);

  redirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SystemActions.redirect),
        tap(({ url }) => this.router.navigateByUrl(url)),
      );
    },
    { dispatch: false },
  );

  redirectToCourtesyPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SystemActions.redirectToCourtesyPage),
        tap(() => {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = environment.b2cPolicies.redirectCourtesyPageUri;
        }),
      );
    },
    { dispatch: false },
  );

  redirectToHomePage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SystemActions.redirectToHomePage),
        tap(() => this.router.navigateByUrl('dias/homepage')),
      );
    },
    { dispatch: false },
  );

  openPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SystemActions.openPage),
        tap(({ url, target }) => window.open(url, target)),
      );
    },
    { dispatch: false },
  );
}
