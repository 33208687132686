/* eslint-disable @ngrx/avoid-mapping-selectors */
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { delay, map, Observable } from 'rxjs';

import { Theme } from './models/core/theme.type';
import { selectLoading, selectTheme } from './store/selectors/system.selectors';
import { AppState } from './store/states/app.state';
import { LoadingSpinnerComponent } from './components/ui-elements/loading-spinner/loading-spinner.component';
import { CubeMSALFlowDirective } from './directives/msal-flow.directive';
import { NotificationHandlerDirective } from './directives/notification-handler.directive';
import { CubeTranslateDirective } from './directives/translate.directive';
import { CubeLayoutModeHandlerDirective } from './directives/layout-mode-handler.directive';
import { OverlayContainer } from '@angular/cdk/overlay';

@Component({
  selector: 'cube-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterOutlet, LoadingSpinnerComponent, CubeMSALFlowDirective, NotificationHandlerDirective, CubeTranslateDirective, CubeLayoutModeHandlerDirective],
})
export class AppComponent {
  isDarkTheme = false;
  loading$: Observable<boolean>;

  constructor(private readonly store: Store<AppState>, private overlayContainer: OverlayContainer) {
    this.loading$ = this.store.select(selectLoading).pipe(delay(500));

    this.store
      .select<Theme>(selectTheme)
      .pipe(
        map((theme: Theme): boolean => theme === 'dark'),
        takeUntilDestroyed()
      )
      .subscribe({
        next: (isDark: boolean) => {
          this.isDarkTheme = isDark;

          this.overlayContainer.getContainerElement().classList.remove(isDark ? 'light-theme' : 'dark-theme');
          this.overlayContainer.getContainerElement().classList.add(!isDark ? 'light-theme' : 'dark-theme');
        },
      });
  }
}
