import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { MSAccessTokenResponse } from '../models/ms-access-token.interface';
import { PowerBITokenResponse } from '../models/powerbi-token.interface';
import { PowerBIConfiguration } from '../models/powerbi-configuration.interface';

export const PowerBIActions = createActionGroup({
  source: 'PowerBI',
  events: {
    PowerBIServicePrincipalLogin: emptyProps(),
    PowerBIServicePrincipalLoginSuccess: props<{ response: MSAccessTokenResponse }>(),
    PowerBIServicePrincipalLoginFailure: props<{ error: HttpErrorResponse }>(),
    PowerBIToken: emptyProps(),
    PowerBITokenSuccess: props<{ response: PowerBITokenResponse }>(),
    PowerBITokenFailure: props<{ error: HttpErrorResponse }>(),
    PowerBITokenConfiguration: props<{ configuration: PowerBIConfiguration }>(),
  },
});
