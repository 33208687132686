import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable, throwError } from 'rxjs';

import { MessageType } from 'src/app/models/core/system-notification.interface';
import { SystemActions } from 'src/app/store/actions/system.actions';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse) => {
        let message = '';
        if (req.url.includes('blob.core.windows.net') && response.status === HttpStatusCode.NotFound) {
          message = response.statusText;
        } else if (req.url.includes('CarbonCreditsHttpTrigger')) {
          message = req.method === 'POST' ? 'Carbon credit creation failed' : 'Carbon credit update failed';
        } else {
          message = response.error.error;
        }

        this.store.dispatch(SystemActions.setNotification({ notification: { type: MessageType.Error, message } }));
        return throwError(() => response);
      })
    );
  }
}
