import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter, switchMap, take } from 'rxjs';

import { MSAccessTokenResponse } from 'src/app/features/powerbi/models/ms-access-token.interface';
import { feature } from 'src/app/features/powerbi/store';
import { AppState } from 'src/app/store/states/app.state';
import { environment } from 'src/environments/environment';

@Injectable()
export class PowerBITokenInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<AppState>) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith(environment.powerbi.base_url)) {
      return this.store.select(feature.selectPowerbi_access_token).pipe(
        filter((ms_access_token: MSAccessTokenResponse | undefined): ms_access_token is MSAccessTokenResponse => !!ms_access_token),
        take(1),
        switchMap((ms_access_token: MSAccessTokenResponse) => {
          return next.handle(
            req.clone({
              setHeaders: {
                Authorization: `Bearer ${ms_access_token.access_token}`,
              },
            }),
          );
        }),
      );
    } else {
      return next.handle(req);
    }
  }
}
