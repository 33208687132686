/* eslint-disable @ngrx/no-typed-global-store */
import { Directive, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, EventMessage, EventType } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

import { TokenDetails } from '../models/auth/token-details.type';
import { AuthActions } from '../store/actions/auth.actions';
// import { selectTokenDetails, selectUserTenants } from '../store/selectors/auth.selectors';
import { AppState } from '../store/states/app.state';
import { selectTokenDetails, selectUserTenants } from '../store/selectors/auth.selectors';
import { concatLatestFrom } from '@ngrx/effects';

@Directive({ selector: '[cubeMsalFlow]', standalone: true })
export class CubeMSALFlowDirective implements OnInit {
  constructor(
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly store: Store<AppState>,
  ) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(AuthActions.setActiveAccount());
        },
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((message: EventMessage) => ([EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS] as Array<EventType>).includes(message.eventType)),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: (message: EventMessage) => {
          const tokenDetails = message.payload as unknown as TokenDetails;
          this.store.dispatch(AuthActions.setTokenDetails({ tokenDetails }));
        },
      });

    this.store
      .select(selectTokenDetails)
      .pipe(
        filter((tokenDetails: TokenDetails | undefined): tokenDetails is TokenDetails => !!tokenDetails),
        concatLatestFrom(() => this.store.select(selectUserTenants)),
        takeUntilDestroyed(),
      )
      .subscribe({
        next: ([tokenDetails, tenants]) => {
          if (!tenants.length) {
            this.store.dispatch(AuthActions.getUserTenants({ uniqueId: tokenDetails.uniqueId }));
          }
        },
      });
  }

  ngOnInit(): void {
    this.store.dispatch(AuthActions.redirectToGateway());
  }
}
