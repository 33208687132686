import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MSAccessTokenResponse } from '../models/ms-access-token.interface';
import { PowerBITokenResponse } from '../models/powerbi-token.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class PowerBiHttpService {
  constructor(private readonly http: HttpClient) {}

  getMicrosoftAccessToken(): Observable<MSAccessTokenResponse> {
    return this.http.get<MSAccessTokenResponse>(`https://digitalconsulting-api-dev.azure-api.net/ms-login`);
  }

  getPowerBiEmbedToken(): Observable<PowerBITokenResponse> {
    const body = {
      accessLevel: 'View',
      allowSaveAs: 'false',
    };

    return this.http.post<PowerBITokenResponse>(
      `https://api.powerbi.com/v1.0/myOrg/groups/${environment.powerbi.groupId}/reports/${environment.powerbi.reportId}/GenerateToken`,
      body,
    );
  }
}
